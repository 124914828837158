/*
 * @Description: 
 * @Author: wangxiaole
 * @Date: 2022-08-11 13:29:50
 * @LastEditTime: 2022-08-11 13:30:11
 * @LastEditors:  
 */
const errorRouter = {
  path: '/middle',
  name: 'middle',
  component: () => import('../views/middle/middle.vue'),
};
export default errorRouter;
