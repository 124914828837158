<!--
 * @Description: 
 * @Author: wangxiaole
 * @Date: 2023-05-18 16:37:35
 * @LastEditTime: 2023-06-25 14:20:21
 * @LastEditors:  
-->
<template>
  <div class="water-content">
    <div style="width: 100%">
      <template v-for="count in 500">
        <div class="water-font">{{ name }}_{{ time }}</div>
      </template>
    </div>
  </div>
</template>
<script>
import { formatTime } from '../../utils/date/dateformat';
export default {
  data() {
    return {
      name: this.$cookies.get('ssousername'),
      time: formatTime({ date: new Date(), formatStr: 'YYYY-MM-dd HH:mm' })
    };
  },
  created() {
  },
  mounted() {
    window.$eventBus.addEventListener('updateWaterBgTime', this.updateTime);
  },
  destroyed() {
    // 移除监听事件
    window.$eventBus.removeEventListener('updateWaterBgTime', this.updateTime);
  },
  methods: {
    updateTime() {
      this.time = formatTime({ date: new Date(), formatStr: 'YYYY-MM-dd HH:mm' })
    }
  }
};
</script>
<style>
.water-content {
  pointer-events: none;
  position: fixed;
  left: 210px;
  width: 100%;
  font-family: Arial;
  height: 100%;
  z-index: 9998;
  font-size: 14px;
  font-weight: 300;
  color: #000;
  display: flex;
  text-align: left;
}
.water-font {
  opacity: 0.05;
  width: 350px;
  height: 300px;
  float: left;
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
</style>
