<template>
  <div class="notice_warp" v-show="showPop">
    <div class="notice_icon" />
    <div class="notice_content" :class="{ '' : isHasOrderRights}">
      <div v-if="roomOrderNum > 0 && hasOrderRights">
        您有
        <span @click="jumpPage">{{ roomOrderNum }}个客房订单</span>
        需要处理
      </div>
      <div v-if="inHotelOrderNum > 0 && hasZDFWRights">
        您有
        <span @click="jumpPage">{{ inHotelOrderNum }}个在店服务新单</span>
        需要处理
      </div>
    </div>
    <div id="newOrderAudio" />
    <!--<input type="text" autofocus id="txt" @click="textClick"/>-->
  </div>
</template>

<script type="text/ecmascript-6">
import HttpUtil from '../../utils/http/HttpUtil';


export default {
  name: 'OrderNotice',
  data() {
    return {
      roomOrderNum: '',
      inHotelOrderNum: '',
      showPop: false,
      sound: null,
      hasOrderRights: false,
      hasZDFWRights: false,
      orderInterval: null,
      titleInterval: null,
    };
  },
  created() {
  },
  mounted() {
  },
  destroyed() {
    this.titleStop();
    this.orderStop();
  },
  methods: {
    // 轮训获取新订单数量
    autoGetNum() {

      // eslint-disable-next-line no-underscore-dangle
      const _this = this;
      this.orderStop();
      this.orderInterval = setInterval(() => {
        _this.getNewOrderNum();
      }, 3 * 60 * 1000);
    },
    // 获取新订单数量 type标志是否为默认的那次提醒
    getNewOrderNum(type) {
      // eslint-disable-next-line no-underscore-dangle
      const _this = this;
      HttpUtil.fetch({
        url: '/crs_api/order/merchantDesktopNewOrderRemind',
        data: {},
        isLoadingPlugin: false,
        tickNext: true,
        success(response) {
          if (response.retcode === 0) {
            const data = response.body ? response.body : {};
            _this.$nextTick(() => {
              _this.roomOrderNum = data.roomOrderNum ? data.roomOrderNum : 0;
              _this.inHotelOrderNum = data.inHotelOrderNum ? data.inHotelOrderNum : 0;
              if ((_this.roomOrderNum > 0 && _this.hasOrderRights)
                || (_this.inHotelOrderNum > 0 && _this.hasZDFWRights)) {
                _this.showPop = true;
                _this.titleMoveStart();
                if (!type) {
                  _this.audioPlay();
                }
              } else {
                _this.showPop = false;
                _this.titleStop();
              }
            });
          }
        },
        error() {
        },
      });
    },
    // 播放音频
    audioPlay() {
      const elem = document.getElementById('audio'); //  音频播放dom
      const newOrderAudioDom = document.getElementById('newOrderAudio'); // 音频播放父级dom
      if (elem && elem !== null) { // 如果音频播放dom存在则先移除
        newOrderAudioDom.removeChild(elem);
      }
      const ua = navigator.userAgent;
      const uAppn = navigator.appName;
      let html = ''; // 音频播放dom拼接
      const source = 'https://lc-static.rezenhotels.com/miniprogram/default/home/order_notice.wav';
      if (ua.indexOf('Chrome') > -1 || ua.indexOf('Safari') > -1 || ua.indexOf('Firefox') !== -1) {
        html = `${'<audio  autoplay="autoplay">'
          + '<source src="'}${source}" type="audio/wav"/>`
          + '</audio>';
      } else if (uAppn.indexOf('Microsoft Internet Explorer') !== -1 && document.all) {
        // html = `<object classid="clsid:22D6F312-B0F6-11D0-94AB-0080C74C7E95">
        // <param name="AutoStart" value="1" />
        // <param name="Src" value="${controller_path_prefix}/resources/video/${aName}" />
        // </object>`;
      } else if (uAppn.indexOf('Opera') !== -1) {
        html = `<embed src="${source}" type="audio/mpeg" loop="false"></embed>`;
      } else {
        html = `<embed src="${source}" type="audio/mp3" hidden="true" loop="false" mastersound></embed>`;
      }
      // html = '<audio id="audio" autoplay>
      // <source src="' + source + '" type="audio/mpeg">
      // </audio>'
      newOrderAudioDom.innerHTML = html; // 写入音频播放dom
    },
    // 标题动画
    titleMoveStart() {
      this.titleStop();
      document.title = '您有待处理新订单';
      this.titleInterval = setInterval(() => {
        const { title } = document;
        const lg = title.length;
        let start = 1;
        const newTitle = title.substring(start, lg) + title.substring(0, start);
        document.title = newTitle;
        start += 1;
        if (start > lg) {
          start = 0;
        }
      }, 1000);
    },
    // 标题动画停止
    titleStop() {
      if (this.titleInterval !== null) {
        clearInterval(this.titleInterval);
        document.title = 'Rezen Business';
        this.titleInterval = null;
      }
    },
    // 定时轮训停止
    orderStop() {
      if (this.orderInterval !== null) {
        clearInterval(this.orderInterval);
        this.orderInterval = null;
      }
    },
    // 跳转到工作台
    jumpPage() {
      // this.$router.push({ path: '/hotel/workflow' });
       window.location.href = '/inhotel/workflow';
    },
  },
  computed: {
    isHasOrderRights() {
      let hasOrderRights = false;
      let hasZDFWRights = false;
      // const { userRights } = this.$store.getters;
      const permissions = localStorage.getItem('uiPermissionCodes');
      // for (let i = 0; i < userRights.length; i++) {
      if (permissions.indexOf('el-hotel-ui-roomOrder') >= 0) {
        hasOrderRights = true;
      }
      if (permissions.indexOf('el-hotel-ui-roomServiceReservationSlip') >= 0) {
        hasZDFWRights = true;
      }
      // }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.hasOrderRights = hasOrderRights;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.hasZDFWRights = hasZDFWRights;
      if (hasOrderRights || hasZDFWRights) {
        this.getNewOrderNum(1);
        this.autoGetNum();
      }
      return hasOrderRights;
    },
  },
};
</script>

<style>
.notice_warp {
  position: absolute;
  z-index: -1;
  top: 64px;
  right: 45px;
}

.notice_icon {
  position: absolute;
  top: -4px;
  right: 30px;
  width: 10px;
  height: 5px;
  background-image: url(../../assets/icon_notice.png);
  background-size: 100% 100%;
}

.notice_content {
  padding: 6px 10px;
  background: #fff;
  border: 1px solid #edeef0;
  border-radius: 4px;
}

.notice_content div {
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #edeef0;
}

.notice_content div:last-child {
  border-bottom: 0;
}

.notice_content div span {
  padding: 0 3px;
  color: #f5a623;
  cursor: pointer;
}
</style>
