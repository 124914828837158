<template>
  <div class="permission-tab-title" v-if="tabList.length > 0">
    <router-link
      v-for="(tabItem, index) in tabList"
      v-bind:key="tabItem.permissionUrl"
      :to="{path: tabItem.permissionUrl, query: tabItem.query}"
      :class="{'active':index===currentTabIndex}"
      @click.native="tabItemClick(index)"
    >
      {{ tabItem.permissionName }}
      <span
        v-if="tabItem.permissionCode
          && tabNum[tabItem.permissionCode]
          && tabNum[tabItem.permissionCode] >0"
        class="tabsup"
      >{{ tabNum[tabItem.permissionCode] }}</span>
    </router-link>
    <div style="border-bottom: 1px solid #eee;"></div>
  </div>
</template>

<script type="text/ecmascript-6">
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import groupUrl from './permission_data/group_url.json';
import hotelUrl from './permission_data/hotel_url.json';
import platformUrl from './permission_data/platform_url.json';

// 清掉tabNum的处理，因为定时器时间不好确定，暂时不清
// let tabNumTimer = null;
export default {
  name: 'PermissionTab',
  props: {
    currentUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentTabIndex: -1,
      tabList: [],
      supFlg: false,
      supData: {},
      permissionCode: '',
      routerType: 0,
      tabNum: {},
      isNotShowTab: false,
      isDualScreen:false
    };
  },
  methods: {
    // router-link点击事件
    tabItemClick(clickIndex) {
      this.currentTabIndex = clickIndex;
      window.$eventBus.sendEvent('changeMenu');
    },
    getPermissionUrls() {
      if (this.routerType === 1) {
        this.getPlatFormUrls();
      } else if (this.routerType === 2) {
        this.getGroupUrls();
      } else if (this.routerType === 3) {
        this.getHotelUrls();
      }
    },
    // 获取平台层面的url
    getPlatFormUrls() {
      const tmpTabList = platformUrl.platFormLeftMenu;
      this.getTabList(tmpTabList);
    },
    // 获取集团层面的url
    getGroupUrls() {
      const tmpTabList = groupUrl.groupLeftMenu;
      this.getTabList(tmpTabList);
    },
    // 获取酒店层面的url
    getHotelUrls() {
      const tmpTabList = hotelUrl.hotelLeftMenu;
      this.getTabList(tmpTabList);
    },
    getTabList(dataList) {
      let tabList = [];
      for (let i = 0; i < dataList.length; i += 1) {
        const groupCommponentItem = dataList[i];

        if (!groupCommponentItem.children) {
          if (groupCommponentItem.code === this.permissionCode) {
            tabList = groupCommponentItem.permissions;
          }
        } else {
          for (let j = 0; j < groupCommponentItem.children.length; j += 1) {
            const tmpChildItem = groupCommponentItem.children[j];
            if (tmpChildItem.compontionCode === this.permissionCode) {
              tabList = tmpChildItem.permissions;

            }
          }
        }
      }
      this.tabList = tabList;
      // if (this.currentTabIndex === -1) {
        this.getCurrentIndex();
      // }
    },
    getCurrentIndex() {
      for (let i = 0; i < this.tabList.length; i += 1) {
        const tmpItem = this.tabList[i];
        const curUrl = window.location.href;
        if (curUrl.indexOf(tmpItem.permissionUrl) >= 0) {
          this.currentTabIndex = i;
          break;
        }
      }
    },
    updateRouteType(eventData) {
      const routerType = get(eventData, ['detail', 'routerType'], 0);
      const permissionCode = get(eventData, ['detail', 'permissionCode'], '');
      this.routerType = routerType;
      this.permissionCode = permissionCode;
      //隐藏tab
      this.isNotShowTab = get(eventData, ["detail", "isNotShowTab"], false);
      this.isDualScreen = get(eventData, ["detail", "isDualScreen"], false);
      if (this.isNotShowTab) {
        this.$nextTick(() => {
          this.$el.style.display = "none";
        });
      }else{
        this.$nextTick(() => {
          this.$el.style.display = "block";
        });
      }
    },
    updateTabNum(eventData) {
      const num = get(eventData, ['detail', 'data'], {});
      this.tabNum = num;
    }
  },
  created() {
    this.getPermissionUrls();
  },
  mounted() {
    window.$eventBus.addEventListener('routerEventByTo', this.updateRouteType);
    window.$eventBus.addEventListener('tabNumChangeEvent', this.updateTabNum);
  },
  destroyed() {
    // 移除路由监听事件
    window.$eventBus.removeEventListener('routerEventByTo', this.updateRouteType);
    window.$eventBus.removeEventListener('tabNumChangeEvent', this.updateTabNum);
    // 清掉tabNum的处理，因为定时器时间不好确定，暂时不清
    // if (tabNumTimer) clearTimeout(tabNumTimer);
  },
  watch: {
    permissionCode(value, oldvalue) {
      if (value !== oldvalue) {
        this.permissionCode = value;
        this.getPermissionUrls();
      }
    },
    routerType(value, oldvalue) {
      if (value !== oldvalue) {
        this.routerType = value;
        this.getPermissionUrls();
      }
    },
    currentUrl(value, oldvalue) {
      if (value !== oldvalue) {
        this.getPermissionUrls();
      }
    },
    $route(to, from) {
      // eslint-disable-next-line no-empty
      if (to || from) { }
      // this.currentTabIndex = -1;
      this.getCurrentIndex();
    },
  },
  filters: {},
  components: {},
};
</script>

<style scoped>
.tabsup {
  /* position: absolute; */
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #fa555d;
  color: #ffffff;
  border-radius: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 10px;
  vertical-align: top; 
}
</style>
