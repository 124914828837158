<template>
  <div id="top_nav" v-if="topMenus && topMenus.length > 0">
    <div class="top_nav_link_no_line">
        <template v-for="item in topMenus">
          <template v-if="topMenus.length<=1">
            <span>{{item.name}}</span>
          </template>
          <template v-else>
             <router-link :to=item.url>{{item.name}}</router-link>
          </template>
        </template>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get';
export default {
  name: 'TopNav',
  data() {
    return {
      topMenus:undefined
    };
  },
  methods: {
    getTopMenu(val){
     this.topMenus = JSON.parse(val.detail)
    },
     updateRouteType(eventData) {
      //隐藏tab
      this.isNotShowTab = get(eventData, ["detail", "isNotShowTab"], false);
      if (this.isNotShowTab) {
        this.$nextTick(() => {
          this.$el.style.display = "none";
        });
      }else{
        this.$nextTick(() => {
          this.$el.style.display = "block";
        });
      }
    },
  },
  watch: {
  },
  computed: {
    topMenus() {
      return JSON.parse(window.sessionStorage.getItem('topMenus'));

    },
  },
  created() {
    const that = this;
    this.topMenus = JSON.parse(window.sessionStorage.getItem('topMenus'))
  },
  destroyed() {
    window.$eventBus.removeEventListener('routerEventByTo', this.updateRouteType);
    window.$eventBus.removeEventListener('setTopMenus', this.getTopMenu);
  },
  mounted() {
    window.$eventBus.addEventListener('routerEventByTo', this.updateRouteType);
    window.$eventBus.addEventListener('setTopMenus', this.getTopMenu);

  }
};
</script>

<style scoped>
#top_nav {
  text-align: left;
  background-color: #fff;
}
#top_nav .top_nav_link{
  border: none;
  position: relative;
  padding:0 28px;
}
#top_nav .top_nav_link_no_line{
  border: none;
  position: relative;
}
#top_nav .top_nav_link::after {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  content: '';
  border-bottom: 1px solid rgba(227,231,237,1); 
  padding: 0 5px 0 5px;
  bottom: 0;
  left:0;
}
#top_nav span {
  color: #0F2347;
  font-size: 20px;
  font-family: Microsoft YaHei;
  display: inline-block;
  margin: 16px 36px 16px 0px;
  height: 26px;
  line-height: 26px;
  font-weight: 400;
  text-decoration: none;
  margin-bottom: 16px;
}
#top_nav a {
  color: #0F2347;
  font-size: 16px;
  font-family: Microsoft YaHei;
  display: inline-block;
  height: 33px;
  line-height: 20px;
  text-decoration: none;
  margin: 10px 36px 0 0;
}
#top_nav a:hover {
  color: #0F2347;
  position: relative;
  font-weight: 400;
  font-size: 16px;
}
#top_nav a.router-link-active {
  border-bottom: 3px solid #FFB400;
  font-weight: 700;
  position: relative;
  font-size: 16px;
}
</style>
