<template>
  <div class="topbar">
    <div style="background:#050533;width:200px;height:40px">
      <div class="logo-new">
        <img style="width:90%;height:90%;margin-left:10px;margin-top:5px"
             src="../../assets/logo40.png"
             alt>
      </div>
    </div>
    <Account :isCompany="true" />
    <a
      class="item-name1"
      v-if="hotelStaffType==='tenant'||hotelStaffType==='subgroup'"
      href="javascript:;"
      @click="showSelectHotelDialog"
    >切换酒店</a>
    <!-- 遮罩背景 -->
    <div class="modal-backdrop" style="display: block;" v-show="showDialogFlag" />

    <!-- ### 弹窗 ### -->
    <div class="modal" style="display: block;" v-show="showDialogFlag">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <a class="close" href="javascript:;" @click="close" />
            <h4 class="modal-title">酒店列表</h4>
          </div>
          <div class="modal-body lab-w65 headscroll">
            <div class="modal-row">
              <div class="label">当前酒店</div>
              <div class="row-con">{{ hotelName }}</div>
            </div>
            <div class="toggle-hotel" v-bind:key="pi" v-for="(provice,pi) in hotelList">
              <div class="province">{{ provice.provinceName }}</div>
              <div class="modal-row" v-bind:key="ci" v-for="(city,ci) in provice.citys">
                <div class="label">{{ city.cityName }}</div>
                <div class="row-con">
                  <ul class="checkbox-o">
                    <li
                      class="checktext"
                      :class="{ 'checked': hotel.checked }"
                      v-bind:key="hi"
                      v-for="(hotel,hi) in city.hotels"
                      @click="selectHotel(pi,ci,hi)"
                    >
                      <i />
                      <p>{{ hotel.hotelName }}</p>
                    </li>
                    <!-- 选中状态 .checked -->
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a class="btn btn-default" href="javascript:;" @click="close">取 消</a>
            <a class="btn btn-primary" href="javascript:;" @click="selectHotel1">确 定</a>
          </div>
        </div>
      </div>
    </div>
    <orderNotice />
  </div>
</template>

<script type="text/ecmascript-6">
import Account from './account.vue';
import orderNotice from './order-notice.vue';
import HttpUtil from '../../utils/http/HttpUtil';
// import CommonUtil from '../../utils/common/CommonUtil.js';

export default {
  name: 'HeadBar',
  data() {
    return {
      hotelName: '',
      tempHotelName: '',
      hotelStaffType: '',
      showDialogFlag: false,
      hotelList: [],
      hotelId: 0,
    };
  },
  created() {
    this.hotelStaffType = this.$cookies.get('hotelStaffType');
    // this.hotelStaffType = this.hotelStaffType.substring(0, this.hotelStaffType.lastIndexOf('/'));
    this.getHotelInfo();
    this.getHotelListByGroup();
  },
  methods: {
    // 选择酒店
    selectHotel1() {
      if (this.hotelId === 0) {
        HttpUtil.showToast('error', '请选择酒店');
        return;
      }

      // eslint-disable-next-line no-underscore-dangle
      const _this = this;
      const req = {
        hotelId: _this.hotelId,
      };
      HttpUtil.fetch({
        url: '/merchant/manager/staffLoginSelectHotel',
        data: req,
        isLoadingPlugin: true,
        success(response) {
          if (response.retcode === 0) {
            _this.$nextTick(() => {
              if (_this.$cookies.get('hotelId')) {
                _this.$cookies.remove('hotelId');
              }
              if (_this.$cookies.get('rememberMe')) {
                _this.$cookies.set('hotelId', _this.hotelId, 31 * 24 * 60 * 60);
              } else {
                _this.$cookies.set('hotelId', _this.hotelId, 31 * 24 * 60 * 60);
              }
              _this.close();
              _this.$router.replace({ path: '/inhotel/workflow' });

              window.location.href = '';
              // 保存酒店名称
              const localSrorage = window.localStorage;
              localSrorage.hotelName = _this.tempHotelName;
            });
          } else {
            HttpUtil.showToast('error', response.retdesc);
          }
        },
        error() {
          HttpUtil.showToast('error', '网络异常');
        },
      });
    },
    selectHotel(pi, ci, hi) {
      for (let i = 0; i < this.hotelList.length; i += 1) {
        for (let j = 0; j < this.hotelList[i].citys.length; j += 1) {
          for (let k = 0; k < this.hotelList[i].citys[j].hotels.length; k += 1) {
            this.hotelList[i].citys[j].hotels[k].checked = false;
          }
        }
      }
      this.hotelList[pi].citys[ci].hotels[hi].checked = true;
      this.hotelId = this.hotelList[pi].citys[ci].hotels[hi].hotelId;
      this.tempHotelName = this.hotelList[pi].citys[ci].hotels[hi].hotelName;
    },

    /**
     * 关闭弹窗
     */
    close() {
      for (let i = 0; i < this.hotelList.length; i += 1) {
        for (let j = 0; j < this.hotelList[i].citys.length; j += 1) {
          for (let k = 0; k < this.hotelList[i].citys[j].hotels.length; k += 1) {
            this.hotelList[i].citys[j].hotels[k].checked = false;
          }
        }
      }
      this.hotelId = 0;
      this.showDialogFlag = false;
    },
    showSelectHotelDialog() {
      this.showDialogFlag = true;
    },
    // 获取酒店信息
    getHotelInfo() {
      // eslint-disable-next-line no-underscore-dangle
      const _this = this;
      const req = {};
      HttpUtil.fetch({
        url: '/merchant/product/hotel/getHotelByHotelId',
        data: req,
        isLoadingPlugin: true,
        success(response) {
          if (response.retcode === 0) {
            if (response.body) {
              const data = response.body;
              _this.$nextTick(() => {
                _this.hotelName = data.hotelName;
                // 保存酒店集团简称
                const localSrorage = window.localStorage;
                localSrorage.groupSimpleName = data.groupSimpleName ? data.groupSimpleName : '';
              });
            } else {
              _this.hotelName = 'Rezen Business';
              // wx.localStorage.
            }
          } else if (response.retcode === 102) {
            if (_this.hotelStaffType === 'tenant') {
              _this.staffLoginSelect();
            } else if (_this.hotelStaffType === 'system') {
              // Rezen Business帐号
              this.$cookies.set('hotelId', 0, 31 * 24 * 60 * 60);
              // this.$router.push({ path: '/platform' });
              window.location.href = '/user/hotelIndex';
            }
          } else {
            HttpUtil.showToast('error', response.retdesc);
          }
        },
        error() {
          HttpUtil.showToast('error', '网络异常，获取酒店信息失败');
        },
      });
    },
    // 获取当前集团酒店列表
    getHotelListByGroup() {
      // eslint-disable-next-line no-underscore-dangle
      const _this = this;
      const req = {};
      HttpUtil.fetch({
        url: '/merchant/product/group/getBasicHotelsByGroupIdSplitByRegion',
        data: req,
        isLoadingPlugin: true,
        success(response) {
          if (response.retcode === 0) {
            if (response.body) {
              const data = response.body;
              //                _this.hotelList = data.provinces

              for (let i = 0; i < data.provinces.length; i += 1) {
                const prodata = {
                  provinceName: data.provinces[i].provinceName,
                  citys: [],
                };
                const citysList = [];

                for (let j = 0; j < data.provinces[i].citys.length; j += 1) {
                  const cityData = {
                    cityName: data.provinces[i].citys[j].cityName,
                    hotels: [],
                  };
                  const hotelList = [];
                  for (
                    let k = 0;
                    k < data.provinces[i].citys[j].hotels.length;
                    k += 1
                  ) {
                    const hotelData = {
                      hotelId: data.provinces[i].citys[j].hotels[k].hotelId,
                      hotelName: data.provinces[i].citys[j].hotels[k].hotelName,
                      checked: false,
                    };
                    hotelList.push(hotelData);
                  }
                  cityData.hotels = hotelList;
                  citysList.push(cityData);
                }

                prodata.citys = citysList;
                _this.hotelList.push(prodata);
              }
            }
          } else {
            HttpUtil.showToast('error', response.retdesc);
          }
        },
        error() {
          HttpUtil.showToast('error', '网络异常，获取酒店列表失败');
        },
      });
    },
    staffLoginSelect() {
      // eslint-disable-next-line no-underscore-dangle
      const _this = this;
      HttpUtil.fetch({
        url: '/merchant/manager/staffLoginSelect',
        data: {
          groupId: _this.$cookies.get('groupId'),
        },
        success(response) {
          if (response.retcode === 0) {
            _this.$nextTick(() => {
              _this.$cookies.set('hotelId', 0, 31 * 24 * 60 * 60);
              window.location.href = '/lcproduct/customer-index';g
            });
          }
        },
        error() { },
      });
    },
    /** 点击酒店返回workflow */
    toHotelHome() {
      if (this.hotelStaffType !== 6) {
        this.close();
        this.$router.replace({ path: '/inhotel/workflow' });
      }
    },
  },
  components: { Account, orderNotice },
};
</script>

<style>
.topbar-brand-new{
  display: block;
  height: 34px;
  float: left;
  color: #fff;
  font-size: 14px;
}
.logo-new{
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
}
.item-name1 {
  display: block;
  padding: 0 0 0 25px;
  height: 60px;
  line-height: 60px;
  color: #fff;
  font-size: 13px;
  float: right;
  margin-right: 10px;
  position: relative;
}
</style>
