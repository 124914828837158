const defaultState = {
  code: '',
};
// getters
const getters = {
  getWXcode: state => state.code,
};

const mutations = {
  setCode(state, code) {
    state.code = code;
  },

};

export default {
  state: defaultState,
  mutations,
  getters,
};
