
import './set-public-path';
import Vue from 'vue';
import Vuex from 'vuex';
import AvocadoLoadVue from 'avocado-load-vue';
import axios from 'axios';
import includes from 'lodash/includes';
import _ from 'lodash';
import packageConfig from '../package.json';
import HttpUtil from './utils/http/http-util-promise';
import router from './router';
import store from './store';
// import permissionButton from './components/permission-button/permission-button.vue';
import App from './App.vue';
import WaterBg from './business-components/waterbg/waterbg.vue';
import PermissionTab from './business-components/permission_tab/permission_tab.vue';
// import LeftMenu from './business-components/left-menu/left_menu.vue';
import NewLeftMenu from './business-components/left-menu/new-left-menu.vue';
import HeaderBar from './business-components/header-bar/header.vue';
import TopNav from './business-components/left-menu/top-nav';
import Loading from './business-components/loading/loading.vue';
import "./less/element-icon.less";
import "./less/index.less";
import Toast from './business-components/toast/toast.vue';
import SensitiveWordDialog from './business-components/sensitive-word-dialog/sensitive-word-dialog.vue';
import './views/common/common.css';

const expireTime = 31 * 24 * 60 * 60;

function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

Vue.config.productionTip = false;

// 添加Vuex支持
Vue.use(Vuex);
Vue.prototype.$http = axios;
Vue.prototype.$ajax = axios;
Vue.prototype.$httpUtil = HttpUtil;


// 添加全局组件
// Vue.component('permissionButton', permissionButton);

// 设置路由生命周期方法
if (_.has(Vue.prototype, '$routerHook')) {
  _.forEach(Vue.prototype.$routerHook, (item, index) => {
    router[index](item);
  });
}

// 使用Avocado创建生命周期对象
const vueLifecycles = AvocadoLoadVue({
  Vue,
  rootDomId: 'crs-comm',
  // 应用节点名称
  appName: packageConfig.name,
  appOptions: {
    render: h => h(App),
    store,
    router,
  },
});

export const { bootstrap } = vueLifecycles;
export const { mount } = vueLifecycles;
export const { unmount } = vueLifecycles;
export const { update } = vueLifecycles;


// 添加全局遮罩类组件
new Vue({
  render: h => h(Loading),
}).$mount('#crs-loading');

new Vue({
  render: h => h(Toast),
}).$mount('#crs-toast');
// 渲染水印
new Vue({
    render: h => h(WaterBg),
}).$mount('#water-bg');
new Vue({
  render: h => h(SensitiveWordDialog),
}).$mount('#crs-sensitive-word-dialog');

// 特殊处理，当前地址不等于登录和错误信息页面时，渲染公共组件
const exclude = ['/loginPage', '/loginFindBack', '/setNewPW', '/setSuccess', '/error-page', '/middle', '/'];
const path = window.location.pathname;
// 如果不包含需要排除的路由定义，则渲染公共组件
if (path === '/' && getCookie('ssoid') === '') {
  let url = '';
  const originUrl = window.location.origin;
  if (originUrl.indexOf('https://beta-b.rezenhotels.com') >= 0) {
    url = 'https://beta-sso.rezenhotels.com';
  } else if (originUrl.indexOf('https://test-b.rezenhotels.cn') >= 0) {
    url = 'https://sso.rezenhotels.cn';
  } else if (originUrl.indexOf('https://test-b.rezengroup.cn') >= 0) {
    url = 'https://sso.rezengroup.cn';
  } else if (originUrl.indexOf('https://b-sh1.rezenhotels.com') >= 0) {
    url = 'https://sso-sh1.rezenhotels.com';
  }
   else {
    url = 'https://sso.rezenhotels.com';
  }
  window.location.replace(`${url}/loginPage`);
} else if (!includes(exclude, path) && getCookie('ssoid') !== '') {

  // 渲染菜单
  new Vue({
    router,
    store,
    render: h => h(NewLeftMenu),
  }).$mount('#crs-menu-bar');
  // 渲染公共导航条
  new Vue({
    router,
    store,
    render: h => h(HeaderBar),
  }).$mount('#crs-nav-bar');
  // 渲染tab组件
  new Vue({
    router,
    store,
    render: h => h(TopNav),
  }).$mount('#permission-tab');
} else if (path === '/' && getCookie('ssoid') !== '') {
  window.location.replace('/lcproduct/customer-index');
}
