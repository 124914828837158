<template>
  <div></div>
</template>

<script type="text/ecmascript-6">
import HttpUtil from '../../utils/http/HttpUtil';

export default {
  data() {
    return {
    };
  },
  beforeCreate() {
  },
  mounted() {
    window.$eventBus.addEventListener('onSensitiveMsg', this.handleEvent);
  },
  destroyed() {
    window.$eventBus.removeEventListener('onSensitiveMsg', this.handleEvent);
  },
  methods: {
    handleEvent(event) {
      const { title, content, options } = event.detail;
      this.show(title, content, options);
    },
    show(title, content, options) {
      this.$confirm(`<div style='white-space: pre-line;'>${content}</div>`, title, {
        confirmButtonText: '修改内容',
        cancelButtonText: '继续提交',
        type: 'warning',
        customClass: 'sensitiveWordDialog',
        showClose: false,
        dangerouslyUseHTMLString: true,
      }).then(() => {
      }).catch(() => {
        HttpUtil.fetch({ ...options, ...{ pass: true } });
      });
    },
  },
};
</script>
<style>
  .sensitiveWordDialog{
    width: 600px;
  }
  .sensitiveWordDialog .el-message-box__content{
    max-height: 500px;
    overflow-y: auto;
  }
</style>
