/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-02-28 15:37:26
 * @LastEditors:  
 * @LastEditTime: 2022-08-11 13:30:31
 */

import Router from 'vue-router';

import defaultRouter from './defaultRouter';
import errorRouter from './errorRouter';
import middleRouter from './middleRouter';
import helpCenterRouter from './helpCenterRouter';

// const redirectPath = {
//   path: '/',
//   redirect: '/hotel/workflow',
// };

const routes = [
  // redirectPath,
  defaultRouter,
  errorRouter,
  helpCenterRouter,
  middleRouter
];

export default new Router({
  mode: 'history',
  routes,
});
