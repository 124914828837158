<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhaoxiang
 * @Date: 2020-03-10 17:52:15
 * @LastEditors:  
 * @LastEditTime: 2022-06-23 15:28:56
 -->
<template>
   <div class="topbar">
    <div style="background:#050533;width:200px;height:40px">
      <div class="logo-new">
       <img style="width:90%;height:90%;margin-left:10px;margin-top:5px"
             src="../../assets/logo40.png"
             alt>
      </div>
    </div>
    <Account :isCompany="true" />
  </div>
</template>

<script type="text/ecmascript-6">
import Account from './account.vue';

export default {
  name: 'HeadBar',
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  },
  components: { Account },
};
</script>

<style>
.topbar-brand-new{
    display: block;
    height: 34px;
    float: left;
    color: #fff;
    font-size: 14px;
}
.logo-new{
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
}
</style>
