<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhaoxiang
 * @Date: 2020-03-10 17:47:17
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-03-10 18:02:51
 -->
<template>
  <div>
    <hotel-header v-if="routerType == 3" />
    <group-header v-if="routerType == 2" />
    <wlt-header v-if="routerType == 1" />
  </div>
</template>
<script>
import get from 'lodash/get';
import hotelHeader from './hotel-header-bar.vue';
import groupHeader from './group-header-bar.vue';
import wltHeader from './wlt-header-bar.vue';

export default {
  name: 'DefaultBar',
  components: {
    hotelHeader,
    groupHeader,
    wltHeader,
  },
  data() {
    return {
      routerType: 0,
    };
  },
  methods: {
    updateRouteType(eventData) {
      const routerType = get(eventData, ['detail', 'routerType'], 0);
      this.routerType = routerType;
    },
  },
  mounted() {
    window.$eventBus.addEventListener('routerEventByTo', this.updateRouteType);
  },
  destroyed() {
    // 移除路由监听事件
    window.$eventBus.removeEventListener('routerEventByTo', this.updateRouteType);
  },
};
</script>
