<!--
 * @Description: 
 * @Author: wangxiaole
 * @Date: 2023-08-02 19:00:19
 * @LastEditTime: 2023-10-13 14:37:38
 * @LastEditors:  
-->
<template>
  <div class="left_nav">
    <div class="first-level-tab">
      <div class="first-level-content-home">
        <div class="hq-icon">
          <img style="width: 48px;height: 32px;" src="../../assets/HQlogo.png" alt="" />
        </div>
      </div>
      <div style="margin-top: 10px;">
        <div @click="selectFirstLevelTab(item)" :key="item.id" v-for="item in menuData" class="firstHover">
          <div v-if="item.childMenus || item.name == '首页'" :class="matchIds.length > 0 && matchIds[0] === item.id
              ? 'first-level-content-select first-content '
              : 'first-content'
            ">
            <div class="first-level-content-icon">
              <span :style="matchIds.length > 0 && matchIds[0] === item.id ? 'color: #222' : 'color:#eee'
                " class="iconSize" v-html="getIconName(item.pictureUrl)"></span>
            </div>
            <span class="first-level-content-text"
              :style="matchIds.length > 0 && matchIds[0] === item.id ? 'color: #222' : 'color:#bbb'">{{ item.name
              }}</span>
          </div>
        </div>
      </div>
    </div>
    <el-scrollbar v-show="showSecondaryMenu">
      <el-container>
        <el-aside class="el-aside" width="126px" style="height: 100vh;;background-color: #F8F8F8;">
          <div style="margin: 10px 10px 2px 10px;">
            <el-input autocomplete="new-password" placeholder="查询" :readonly="readonlyStatus"
              @focus="() => (readonlyStatus = false)" @blur="() => (readonlyStatus = true)" v-model="menuSearchItem">
              <i slot="prefix" style="font-size: 14px;" class="el-icon-search" />
            </el-input>
          </div>
          <el-menu :default-active="menuActivityIndex" style="text-align: left" :unique-opened="true">
            <NavMenu v-if="!menuSearchItem" :isHomePage="isHomePage" @refreshCollect="refreshCollect"
              @setMatchIds="setMatchIds" :navMenus="subMenuData" :allMenu="menuData" :matchIds="matchIds" />
            <NavMenu v-else @resetSearchQuery="resetSearchQuery" :isHomePage="isHomePage" @refreshCollect="refreshCollect"
              @setMatchIds="setMatchIds" :navMenus="searchData" :allMenu="menuData" :matchIds="matchIds" />
          </el-menu>
        </el-aside>
      </el-container>
    </el-scrollbar>
  </div>
</template>

<script type="text/ecmascript-6">
import NavMenu from './new-nav-menu';
export default {
  components: {
    NavMenu,
  },
  data() {
    return {
      menuData: [],
      menuActivityIndex: '',
      subMenuData: [],
      searchData:[],
      iconName:"Home",
      readonlyStatus: true,
      // 是否展示二级和三级菜单
      showSecondaryMenu: false,
      matchIds:[0,0,0,0],
      isHomePage: false,
      menuSearchItem: null,
      indexMenu: [
        {
          name: '我关注的',
          childMenus: null
        }
      ]
    };
  },
  computed: {},
  mounted() {
    window.$eventBus.addEventListener('menuActivityIndex', this.getActivityIndex);
    if (this.$route.path.indexOf('lcproduct/home-index')!==-1) {
      this.subMenuData = this.indexMenu
      this.isHomePage = true
    }
    window.addEventListener('popstate', () => {
      this.setMatchIds(location.pathname + location.search + location.hash)
    }, false)
  },
  created() {
    this.getAllMenu()

  },

  methods: {
    getIconName(a){
      return `<i class="iconfont `+a+`" style="font-size:24px"></i>`
    },
    // 获取全量列表
    getAllMenu() {
      this.$httpUtil.fetch({
        url: '/crs_api/hq/merchant/menu/getUserMenus',
        data: {},
      }).then((resData) => {
        this.menuData = resData.body ? resData.body : []
        this.getMenuCollect()
      });
    },
     // 获取关注列表
    getMenuCollect(isHome) {
      this.$httpUtil.fetch({
        url: '/crs_api/hq/merchant/menu/getUserMenuCollection',
        data: {},
      }).then((resData) => {
        let collect = resData.body.collectionMenus ? resData.body.collectionMenus : []
        this.setMenuCollect(collect)
        this.indexMenu = (resData.body) ? [
            {
              name: '我关注的',
              childMenus: this.formatMenus(resData.body.collectionMenus),
              isHomePage: true
            },
            {
              name: '最近浏览',
              isRecent: true,
              childMenus: this.formatMenus(resData.body.recentlyMenus),
              isHomePage: true
            }
          ] : [
            {
              name: '我关注的',
              childMenus: this.formatMenus(resData.body.collectionMenus),
              isHomePage: true
            }
          ]
          if (this.$route.path.indexOf('lcproduct/home-index') !== -1) {
            this.subMenuData = this.indexMenu
          }
      });
    },
    formatMenus(list) {
      let temp = list && list.map(i => {
        return {
          id: i.id,
          name: i.name,
          url: i.url&&i.url[0]!=='/'?'/'+i.url:i.url,
          isMyCollect: true,
          childMenus: this.formatMenus(i.childMenus)
        }
      })
      return temp
    },
    // 为全局菜单 标注 关注菜单
    setMenuCollect(list){
      const that = this
      for (let menu1 of that.menuData) {
        if (menu1.childMenus && menu1.childMenus.length > 0) {
          for (let menu2 of menu1.childMenus) {
            if (menu2.childMenus && menu2.childMenus.length > 0) {
              for (let menu3 of menu2.childMenus) {
                for(let collect of list){
                if (collect.id === menu3.id) {
                  menu3.isMyCollect = true
                }
              }
              }
            }
          }
        }
      }
      
      this.changeChildMenus(this.menuData,   window.sessionStorage.getItem('menuActivityIndex') ? window.sessionStorage.getItem('menuActivityIndex') : '/lcproduct/home-index')
    },
    setMatchIds(url) {
      this.changeChildMenus(this.menuData,url)
    },
    // 过滤搜索数据
    checkMenuItem(query) {
      const that = this;
      let temp = []
      for (let menu1 of that.menuData) {
        if (menu1.childMenus && menu1.childMenus.length > 0) {
          for (let menu2 of menu1.childMenus) {
            if (menu2.childMenus && menu2.childMenus.length > 0) {
              if (menu2.name.indexOf(query) > -1) {
                temp.push(menu2)
              } else {
                let temp3 = {
                  ...menu2,
                  childMenus: []
                }
                for (let menu3 of menu2.childMenus) {
                  if (menu3.name.indexOf(query) > -1) {
                    temp3.childMenus.push(menu3)
                  }
                }
                if (temp3.childMenus && temp3.childMenus.length > 0) {
                  temp.push(temp3)
                }
              }
            }
          }
        }
      }
      return temp
    },
    // 设置子菜单数据
    changeChildMenus(childMenus,url) {
      const that = this;
      for (const navMenu of childMenus) {
        if (navMenu.childMenus && navMenu.childMenus.length > 0) {
          that.changeChildMenus(navMenu.childMenus,url);
        } else {
          if (navMenu.url.indexOf('/') != 0) {
            navMenu.url = `/${navMenu.url}`;
          }
          let matchRoute = url === navMenu.url
          if (matchRoute) {
            that.matchIds = that.getMatchIds(navMenu.id)
            if (that.matchIds.length > 0) {
              let menu1 = that.menuData && that.menuData.filter(i => i.id === that.matchIds[0])[0]
              that.subMenuData = menu1.url === '/lcproduct/home-index' ? this.indexMenu : menu1.childMenus
              if (!that.subMenuData || that.subMenuData.length === 0) {
                that.showSecondaryMenu = false
              } else {
                that.showSecondaryMenu = true
              }
            }
          }
        }
      }
    },
    getMatchIds(id) {
      const that = this;
      for (let menu1 of that.menuData) {
        if (id === menu1.id) {
          window.sessionStorage.setItem('topMenus',JSON.stringify([]))
          window.$eventBus.sendEvent('setTopMenus',JSON.stringify([]))
          return [menu1.id,0,0,0]
        }
        if (menu1.childMenus && menu1.childMenus.length > 0) {
          for (let menu2 of menu1.childMenus) {
            if (id === menu2.id) {
              window.sessionStorage.setItem('topMenus',JSON.stringify([{ ...menu2,childMenus:null }]))
              window.$eventBus.sendEvent('setTopMenus',JSON.stringify([{ ...menu2,childMenus:null }]))
              return [menu1.id, menu2.id,0,0]
            }
            if (menu2.childMenus && menu2.childMenus.length > 0) {
              for (let menu3 of menu2.childMenus) {
                if (id === menu3.id) {
                  window.sessionStorage.setItem('topMenus',JSON.stringify([{ ...menu3,childMenus:null }]))
                  window.$eventBus.sendEvent('setTopMenus',JSON.stringify([{ ...menu3,childMenus:null }]))
                    return [menu1.id, menu2.id, menu3.id,0]
                }
                if (menu3.childMenus && menu3.childMenus.length > 0) {
                  for (let menu4 of menu3.childMenus) {
                    if (id === menu4.id) {
                      window.sessionStorage.setItem('topMenus',JSON.stringify([...menu3.childMenus]))
                      window.$eventBus.sendEvent('setTopMenus',JSON.stringify([...menu3.childMenus]))
                        return [menu1.id, menu2.id, menu3.id, menu4.id]
                    }
                  }
                }
              }
            }
          }
        }
      }

      return [0,0,0,0]
    },
    // 关注回调
    refreshCollect(query) {
      query.isMyCollect = !query.isMyCollect
      if (this.subMenuData[0].isHomePage) {
        // 前端处理移除收藏菜单后列表更新
        let itemIndex = this.subMenuData[0].childMenus.findIndex(i => i.id === query.id)
        if (itemIndex) {
          this.subMenuData[0].childMenus.splice(itemIndex, 1)
        }
      }
      this.getMenuCollect()
    },
    resetSearchQuery() {
      this.menuSearchItem = null
    },

    // 点击能力项
    selectFirstLevelTab(row) {
      this.menuSearchItem = undefined
      this.isHomePage = row.url && row.url.indexOf('lcproduct/home-index') !== -1
      if(this.isHomePage) {
        this.getMenuCollect(true)
        this.showSecondaryMenu = true
      } else {
        this.subMenuData = row.childMenus
        if (!this.subMenuData || this.subMenuData.length === 0) {
          this.setMatchIds(row.url)
          this.showSecondaryMenu = false
          this.$router.push(row.url)
          return
        }
        this.showSecondaryMenu = true
      }
      this.toDefaultUrl(row)
      this.matchIds = [row.id, 0, 0, 0]
    },
    toDefaultUrl(item) {
      let url = ''
      if(item && !item.childMenus) {
        url = item ? item.url : ''
        this.$router.push(url);
      } else {
        this.toDefaultUrl(item.childMenus[0])
      }
    },
    getActivityIndex(data) {
      if(JSON.stringify(this.menuData).indexOf(data.detail.toString()) !== -1){
      this.menuActivityIndex = data.detail
      window.sessionStorage.setItem('menuActivityIndex',this.menuActivityIndex)
    }
    }
  },
  watch: {
    menuSearchItem(val) {
      this.searchData = this.checkMenuItem(val)
    },
  },
  destroyed() {
    window.$eventBus.removeEventListener('menuActivityIndex', this.getActivityIndex);
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-input__prefix {
  line-height: 24px;
  margin-left: 2px;
  margin-top: 1px;
}

::v-deep .el-input--prefix .el-input__inner {
  padding-left: 24px;
}

::v-deep .el-input__inner {
  width: 82px !important;
  height: 24px !important;
  font-family: PingFangSC-Regular;
  font-size: 12px !important;
  color: #0f2347;
  font-weight: 400;
}

::v-deep .el-menu {
  border-right: 0px;
  background-color: #f8f8f8 !important;
}

::v-deep .el-input__inner:focus {
  border-color: #ffc600 !important;
}

.left_nav {
  width: 190px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
}

.first-level-tab {
  width: 64px;
  height:100vh;
  background: #222;
  overflow-y: auto;
 
}

.first-level-tab::-webkit-scrollbar {
    display: none;
  }

.first-level-content-select {
  background-color: #ffc600;
  color: #222;
}

.first-level-content-home {
  align-items: center;
}

.first-content {
  padding: 14px;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.first-level-content-icon {
  width: 24px;
  height: 24px;
  align-items: center;
  text-align: center;
}

.first-level-content-text {
  text-align: center;
  margin-top: 4px;
  font-size: 12px;
}

.iconSize {
  font-size: 24px;
}

.hq-icon {
  width: 48px;
  height: 32px;
  margin: 8px;
}

.firstHover:hover {
  background: #333;
  cursor: pointer;
}

.el-icon-search {
  color: #999faa;
}
</style>
