<template>
  <div class="topbar-nav">
    <div style="display: flex;
    justify-content: flex-start;">
      <el-select v-model="ssoSystemType"
                 class="div-select"
                 :popper-append-to-body="false"
                 @change="changeValue"
                 placeholder="请选择">
        <el-option v-for="item in options"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value" />
      </el-select>
      <ul>
        <li class="nav-item"
            :class="{ 'active': showList }"
            @mouseover="showList=true"
            @mouseout="showList=false">
          <i class="icon_drop_down" />
          <a class="item-name"
             href="javascript:void(0);">{{ userName }}</a>
          <ul class="item-list">
            <li v-if="userType!== 'hotel' && IndexStatus">
              <a href="javascript:void(0);"
                 @click="jumpToIndex">返回主页</a>
            </li>
            <li v-if="userType!== 'system'">
              <a href="javascript:void(0);"
                 @click="jumpUserInfo">个人信息</a>
            </li>
            <li v-if="isHotel">
              <a href="javascript:void(0);"
                 @click="toHelpCenter">帮助中心</a>
            </li>
            <li>
              <a href="javascript:void(0);"
                 @click="logout">退出</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import Cookies from 'js-cookie';
import HttpUtil from '../../utils/http/HttpUtil';
import systemUrlUtil from '../../utils/common/systemUrlUtil';

export default {
  name: 'Account',
  props: {
    isHotel: {
      type: Boolean,
      default: false,
    },
    isCompany: {
      type: Boolean,
      default: false,
    },
    loginSystemType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      options: [{
        value: '2',
        label: 'Business',
      }, {
        value: '1',
        label: 'HQ',
      }],
      ssoSystemType: '1',
      userName: '',
      userType: '', // hotel:单体酒店帐号 tenant:集团酒店帐号 system:Rezen Business帐号
      showList: false,
      isLoginHotel: '', // 是否登录到酒店
    };
  },
  created() {
    this.isLoginHotel = window.$CheckAuth.checkLoginHotel();
    this.getUserInfo();
  },
  destroyed() {
    this.$store.commit('UPDATE_USER_RIGHTS', []);
  },
  methods: {
    setToken(token) {
      return Cookies.set('ssoid', token, { expires: 30, domain: ['localhost', '127.0.0.1'].includes(document.domain) ? document.domain : systemUrlUtil.handleUrlHost2() });
    },
    async changeValue() {
      // 更新上次登录系统
      await this.updateLastLoginSystemType();
      if (this.ssoSystemType === '2') {
        this.setToken(this.$cookies.get('ssoid'));
        Cookies.set('ssoSystemType', 2, { domain: ['localhost', '127.0.0.1'].includes(document.domain) ? document.domain : systemUrlUtil.handleUrlHost2() });
        // 跳转到上海系统
        const url = systemUrlUtil.handleUrlHostSH();
        window.location.href = `${url}/franchise/index?t=${new Date().getTime()}`;
      }
    },
    updateLastLoginSystemType() {
      const _this = this;
      HttpUtil.fetch({
        url: '/crs_api/business/merchant/updateBusinessLoginSystem',
        data: {
          // 1:rezen business bj,2:rezen business sh
          businessLoginSystem: Number(_this.ssoSystemType),
          userName: _this.$cookies.get('ssousername'),
        },
        success(response) {
          if (response.retcode === 0) {

          } else {
            HttpUtil.showToast('error', response.retdesc);
          }
        },
        error() {
          HttpUtil.showToast('error', '网络异常');
        },
      });
    },
    // 获取用户名及用户角色
    getUserInfo() {
      const username = this.$cookies.get('username');
      const userType = this.$cookies.get('hotelStaffType');
      this.userName = username;
      this.userType = userType;
    },
    // 判断角色跳转
    jumpToIndex() {
      if (this.userType === 'tenant') { // 集团帐号
        this.staffLoginSelect();
      } else if (this.userType === 'system') { // Rezen Business帐号
        this.$cookies.set('hotelId', 0, 31 * 24 * 60 * 60);
        window.location.href = '/user/hotelIndex';
      }
    },
    staffLoginSelect() {
      const _this = this;
      HttpUtil.fetch({
        url: '/merchant/manager/staffLoginSelect',
        data: {
          groupId: _this.$cookies.get('groupId'),
        },
        success(response) {
          if (response.retcode === 0) {
            _this.$cookies.set('hotelId', 0, 31 * 24 * 60 * 60);
            window.location.href = '/lcproduct/customer-index';
          }
        },
        error() {
        },
      });
    },
    jumpUserInfo() {
      this.$router.push({
        path: '/user/groupUserInfo/staffDetail',
        query: { userType: 'tenant' },
      });
      // if (this.userType === 'tenant') {
      //   this.$router.push({
      //     path: '/user/groupUserInfo/staffDetail',
      //     query: { userType: 'tenant' },
      //   });
      // } else if (this.userType === 'hotel') {
      //   this.$router.push({
      //     path: '/user/userInfo/staffDetail',
      //     query: { userType: 'hotel' },
      //   });
      // }
    },
    /** 跳转帮助中心 */
    toHelpCenter() {
      window.location.href = '/help-center';
    },
    handleUrlHost2() {
      let url = '';
      const originUrl = window.location.origin;
      if (originUrl.indexOf('https://beta-b.rezenhotels.com') >= 0) {
        url = '.rezenhotels.com';
      } else if (originUrl.indexOf('https://test-b.rezenhotels.cn') >= 0) {
        url = '.rezenhotels.cn';
      } else if (originUrl.indexOf('https://test-b.rezengroup.cn') >= 0) {
        url = '.rezengroup.cn';
      } else if (originUrl.indexOf('https://b-sh1.rezenhotels.com') >= 0) {
        url = '.rezenhotels.com';
      }
       else {
        url = '.rezenhotels.com';
      }

      return url;
    },
    // 退出登陆
    async logout() {
      // eslint-disable-next-line no-underscore-dangle
      // 更新上次登录系统
      // await this.updateLastLoginSystemType();
      const ssoid = this.$cookies.get('ssoid');
      window.$CheckAuth.ClearAllCookie();
      const _this = this;
      HttpUtil.fetch({
        url: '/crs_api/business/merchant/logout',
        data: {
          sid: ssoid,
        },
        isLoadingPlugin: true,
        success(response) {
          if (response.retcode === 0) {
            _this.$nextTick(() => {
              window.$CheckAuth.clearElementStorage();
              const crsMain = document.getElementById('crs-main');
              crsMain.style.display = 'none';
              let url = '';
              const originUrl = window.location.origin;
              if (originUrl.indexOf('https://beta-b.rezenhotels.com') >= 0) {
                url = 'https://beta-sso.rezenhotels.com';
              } else if (originUrl.indexOf('https://test-b.rezenhotels.cn') >= 0) {
                url = 'https://sso.rezenhotels.cn';
              } else if (originUrl.indexOf('https://test-b.rezengroup.cn') >= 0) {
                url = 'https://sso.rezengroup.cn';
              } else if (originUrl.indexOf('https://b-sh1.rezenhotels.com') >= 0) {
                url = 'https://sso-sh1.rezenhotels.com';
              } 
              else {
                url = 'https://sso.rezenhotels.com';
              }
              _this.$cookies.remove('ssoid');
              Cookies.set('ssoid', '', { domain: ['localhost', '127.0.0.1'].includes(document.domain) ? document.domain : _this.handleUrlHost2() });
              Cookies.set('ssousername', '', { domain: ['localhost', '127.0.0.1'].includes(document.domain) ? document.domain : _this.handleUrlHost2() });
              Cookies.set('ssoSystemType', '', { domain: ['localhost', '127.0.0.1'].includes(document.domain) ? document.domain : _this.handleUrlHost2() });
              window.location.href = `${url}/loginPage`;
              // window.location.reload();
            });
          } else {
            HttpUtil.showToast('error', response.retdesc);
          }
        },
        error() {
          HttpUtil.showToast('error', '网络异常, 退出登录失败');
        },
      });
    },


  },
  computed: {
    IndexStatus() {
      const status = (this.userType === 'system' && !/wlt/.test(this.$route.path)) || (this.userType === 'tenant' && !this.isCompany);

      return status;
    },
  },
};
</script>

<style lang="less" scoped >
.div-select {
  margin-top: 3px;
  /deep/.el-input .el-input__inner {
    border-color: #ffffff !important;
  }
  /deep/.el-input__inner {
    box-sizing: content-box !important;
    width: 70px !important;
    height: 30px !important;
    padding-right: 0 !important;
    border: 1px solid #ffffff !important;
    border-radius: 2px !important;
    font-size: 14px !important;
    line-height: 30px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #222222;
    font-family: MicrosoftYaHei-Bold;
    font-weight: 700;
  }
}
.nav-item1 {
  background: #fff;
  color: #ffc600;
}
.icon_drop_down {
  width: 14px;
  height: 14px;
  background-image: url(../../assets/icon_drop_down.png);
}
</style>
