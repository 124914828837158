<template>
  <section>
    <div>
      <div v-for="secondMenu in navMenus" v-bind:key="secondMenu.id">
        <div class="major-item">
           <div class="secondTitle" style="position: relative;">
            <div v-if="secondMenu.name == '我关注的'" style="top: -2px;position: absolute;margin-left: -16px;font-weight: 400;">
              <i class="iconfont Business-PC-Collect1" style="color:#999faa;font-size: 14px;"></i>  
            </div>
            
            <div v-else-if="secondMenu.name == '最近浏览'" class="collect-icon"  style="top: -2px;position: absolute;margin-left: -24px;font-weight: 400;" >
              <i class="iconfont Business-PC-Browse" style="color:#999faa;font-size: 14px;" ></i>  
            </div>
            {{ secondMenu.name }}
          </div>
          <div v-for="thirdMenu in secondMenu.childMenus" v-bind:key="thirdMenu.id" >
            <div  class="menu-item" @click.stop="routerTo(thirdMenu,3)" :class="((matchIds[2] === thirdMenu.id) || checkHomeIndex(thirdMenu)) ?'sub-item select-sub-item':'sub-item'" >
              <span v-if="!secondMenu.isRecent" @click.stop="setMenuCollect(thirdMenu)">
                <div v-if="thirdMenu.isMyCollect && secondMenu.name != '我关注的'" class="collect-icon ">
                  <i class="iconfont Business-PC-Collect1" style="color:#FFB400;font-size: 14px;" ></i>  
                </div>
                <div v-else class="collect-icon hover-show un-collect">
                  <i class="iconfont Business-PC-Collect1" ></i>
                </div>
              </span>
              <div v-if="((matchIds[2] === thirdMenu.id) || checkHomeIndex(thirdMenu))" class="title-ico"></div>
              <div :class="((matchIds[2] === thirdMenu.id) || checkHomeIndex(thirdMenu)) ?'thirdTitle thirdTitleAct':'thirdTitle'">{{ thirdMenu.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NavMenu',
  props: ['isHomePage', 'navMenus', 'allMenu', 'matchIds', 'collectMenus'],
  data() {
    return {
      collectMenuList: []
    };
  },
  mounted() {
  },
  watch:{
  },
  methods: {
  
    // 如果存在子菜单，则优先选择子菜单的第一个url
    routerTo(navMenu, level) {
      const that = this;
      let tmpMenu = JSON.parse(JSON.stringify(navMenu))
      // 最多遍历到四级菜单
      while (level < 5) {
        if (tmpMenu.childMenus && tmpMenu.childMenus.length > 0) {
          level++;
          tmpMenu = tmpMenu.childMenus[0]
        } else {
          
          that.$router.push(tmpMenu.url);
          that.setUserMenuRecently(tmpMenu)
          this.$emit('resetSearchQuery')
          break;
        }
      }
    },
   
    checkHomeIndex(item) {
      return item.url && item.url.indexOf('lcproduct/home-index') > -1 && this.$route.path.indexOf('lcproduct/home-index') > -1
    },
    setUserMenuRecently(item) {
      let req = {
        menuId: item.id,
        type:2,
        operationType:1
      }
      this.$httpUtil.fetch({
        url: '/crs_api/hq/merchant/menu/saveUserMenuCollection',
        data: req,
      }).then((resData) => {
      });
    },
    
    setMenuCollect(item) {
      let operationType = 1 
      if(item.isMyCollect){
        operationType = 2
      }
      let req = {
        menuId: item.id,
        type:1,
        operationType:operationType
      }
      this.$httpUtil.fetch({
        url: '/crs_api/hq/merchant/menu/saveUserMenuCollection',
        data: req,
      }).then((resData) => {
        let  statusText = ''
          if (item.isMyCollect) {
            statusText = '取消收藏'
          } else {
            statusText = '收藏'
          }
          this.$message({
            type: 'success',
            message:  statusText
          });
          setTimeout(() => {
            this.$emit('refreshCollect', item)
          },100)
      });
      
    }
  },
};
</script>
<style scoped>

.secondTitle{
  font-family: PingFangSC-Medium;
font-size: 12px;
color: #0F2347;
letter-spacing: 0;
line-height: 20px;
font-weight: 600;
margin-left: 24px;
margin-top: 14px;
}
.thirdTitle{
  font-family: PingFangSC-Regular;
font-size: 12px;
color: #3e4b6c;
letter-spacing: 0;
line-height: 20px;
font-weight: 400;
margin-left: 24px;
}
.thirdTitleAct{
  color: #0f2347;
}
.major-item {
  line-height: 16px;
  min-height: 28px;
  font-size: 12px;
  color: #071022;
  width: 90px;
  letter-spacing: 0;
  font-weight: 700;
  padding-bottom: 18px;
  width: 100%;
}

.sub-item {
  position: relative;
  cursor: pointer;
  flex-wrap: wrap;
  line-height: 16px;
  font-size: 12px;
  min-height: 28px;
  color: #3E4B6C;
  letter-spacing: 0;
  font-weight: 400;
  box-sizing: border-box;
  padding: 6px 20px 6px 0;
}
.sub-item::active {
  color: #0F2347;
}

.title-ico {
  position: absolute;
  top: 0;
  right: 1px;
  width: 4px;
  height: 100%;
  /* margin-right: 8px; */
  background: #FFC600;
}

.collect-icon {
  position: absolute;
  top: 7px;
  left: 8px;
  width: 4px;
  height: 100%;
}
.Business-PC-Collect1{
  color:#ccc;
  font-size: 14px;
}
.hover-show .Business-PC-Collect1:hover{
  color: #FFB400;
}
.hover-show {
  display: none;
}
.menu-item:hover .hover-show {
  display: block;
}

.un-collect svg:hover path{
    fill: #FFB400;
  }


.select-sub-item {
  color: #0F2347;
}

.select-sub-item:hover {
  background: #FFF3CB;
}
.sub-item:hover {
  background: #f0f0f0;

}

.select-sub-item,
.sub-item:active {
  background: #FFF3CB;
}
</style>
