<!--
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-03-10 17:51:32
 * @LastEditors:  
 * @LastEditTime: 2023-10-12 14:22:55
 -->
<template>
  <div class="topbar">
   
    <div>
      <!-- <el-select v-model="ssoSystemType"
                 class="div-select"
                 @change="changeValue"
                 placeholder="请选择">
        <el-option v-for="item in options"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value" />
      </el-select> -->
      <Account :isCompany="true" />
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import Cookies from 'js-cookie';
import Account from './account.vue';
import HttpUtil from '../../utils/http/HttpUtil';
import systemUrlUtil from '../../utils/common/systemUrlUtil';

export default {
  name: 'HeadBar',
  data() {
    return {
      groupName: '',
      logoUrl: '',
      groupId: '',
      options: [{
        value: '2',
        label: 'Business',
      }, {
        value: '1',
        label: 'HQ',
      }],
      ssoSystemType: '1',
    };
  },
  created() {
    this.getGroupInfo();
    this.staffLoginSelect();
  },
  methods: {
    setToken(token) {
      return Cookies.set('ssoid', token, { domain: ['localhost', '127.0.0.1'].includes(document.domain) ? document.domain : systemUrlUtil.handleUrlHost2() });
    },
    async changeValue() {
      // 更新上次登录系统
      await this.updateLastLoginSystemType();
      if (this.ssoSystemType === '2') {
        this.setToken(this.$cookies.get('ssoid'));
        // 跳转到上海系统
        const url = systemUrlUtil.handleUrlHostSH();
        window.location.href = `${url}/franchise/index?t=${new Date().getTime()}`;
      }
    },
    updateLastLoginSystemType() {
      const _this = this;
      HttpUtil.fetch({
        url: '/crs_api/business/merchant/updateBusinessLoginSystem',
        data: {
          // 1:rezen business bj,2:rezen business sh
          businessLoginSystem: Number(_this.ssoSystemType),
          userName: _this.$cookies.get('ssousername'),
        },
        success(response) {
          if (response.retcode === 0) {

          } else {
            HttpUtil.showToast('error', response.retdesc);
          }
        },
        error() {
          HttpUtil.showToast('error', '网络异常');
        },
      });
    },
    staffLoginSelect() {
      // eslint-disable-next-line no-underscore-dangle
      const _this = this;
      HttpUtil.fetch({
        url: '/merchant/manager/staffLoginSelect',
        data: {
          groupId: _this.$cookies.get('groupId'),
          hotelId: _this.$cookies.get('hotelId') ? _this.$cookies.get('hotelId') : null,
        },
        success(response) {
          const data = response.body ? response.body : {};
          if (response.retcode === 0) {
            // self.$nextTick(() => {
            // });
          }
        },
        error(error) {
        },
      });
    },
    // 获取集团信息
    getGroupInfo() {
      // eslint-disable-next-line no-underscore-dangle
      const _this = this;
      HttpUtil.fetch({
        url: '/tenant/getTenant',
        data: {},
        success(response) {
          if (response.retcode === 0) {
            const data = response.body ? response.body : {};
            _this.$nextTick(function () {
              this.groupName = data.tenantUnit.name ? data.tenantUnit.name : '';
              this.logoUrl = data.pcmTenant.logoPath ? data.pcmTenant.logoPath : '';
              // 保存酒店集团简称
              const localSrorage = window.localStorage;
              localSrorage.groupSimpleName = data.pcmTenant && data.pcmTenant.simpleName ? data.pcmTenant.simpleName : '';
            });
          } else {
            HttpUtil.showToast('error', response.retdesc);
          }
        },
        error() {
          HttpUtil.showToast('error', '网络异常，集团信息获取失败');
        },
      });
    },
  },
  components: { Account },
};
</script>

<style lang="less" scoped>
/deep/.el-input__inner {
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  color: #222222;
}
/deep/ .el-input .el-input__inner {
  border-color: #dcdfe6 !important;
}
.div-select {
  float: right;
  margin-right: 170px;
  margin-top: -40px;
  position: relative;
  padding-top: 3px;
}
.topbar-brand-new {
  display: block;
  height: 34px;
  float: left;
  color: #fff;
  font-size: 14px;
}
.logo-new {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
}
</style>
