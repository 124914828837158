/*
 * @Description: 
 * @Author: wangxiaole
 * @Date: 2021-12-28 14:42:00
 * @LastEditTime: 2022-08-09 15:29:27
 * @LastEditors:  
 */

const {
  fetch,
  download,
  urlToDownload,
  showToast,
} = window.$axios;
export default {
  showToast,
  fetch,
  download,
  urlToDownload,
};
