/*
 * @Description: 
 * @Author: wangxiaole
 * @Date: 2023-05-18 16:53:32
 * @LastEditTime: 2024-03-13 18:05:55
 * @LastEditors:  
 */

/**
 * 根据路径匹配出对应的域名
 * @param path 接口路径
 * @returns {string} 请求的绝对路径
 */
function handleUrlHost() {
  let url = '';
  const originUrl = window.location.origin;
  if (originUrl.indexOf('https://beta-sso.rezenhotels.com') >= 0) {
    url = 'https://beta-b.rezenhotels.com';
  } else if (originUrl.indexOf('https://sso.rezenhotels.cn') >= 0) {
    url = 'https://test-b.rezenhotels.cn';
  } else if (originUrl.indexOf('https://sso.rezengroup.cn') >= 0) {
    url = 'https://test-b.rezengroup.cn';
  } else if (originUrl.indexOf('https://sso-sh1.rezenhotels.com') >= 0) {
    url = 'https://b-sh1.rezenhotels.com';
  } else {
    url = 'https://b.rezenhotels.com';
  }

  return url;
}
function handleUrlHost2() {
  let url = '';
  const originUrl = window.location.origin;
  if (originUrl.indexOf('https://beta-b.rezenhotels.com') >= 0) {
    url = '.rezenhotels.com';
  } else if (originUrl.indexOf('https://test-b.rezenhotels.cn') >= 0) {
    url = '.rezenhotels.cn';
  } else if (originUrl.indexOf('https://test-b.rezengroup.cn') >= 0) {
    url = '.rezengroup.cn';
  }  else if (originUrl.indexOf('https://b-sh1.rezenhotels.com') >= 0) {
    url = '.rezenhotels.com';
  }else {
    url = '.rezenhotels.com';
  }

  return url;
}
function handleUrlHostSH() {
  let url = '';
  const originUrl = window.location.origin;
  if (originUrl.indexOf('https://beta-b.rezenhotels.com') >= 0) {
    url = 'https://test1-rezenbusiness.rezenhotels.cn/';
  } else if (originUrl.indexOf('https://test-b.rezenhotels.cn') >= 0) {
    url = 'https://test1-rezenbusiness.rezenhotels.cn';
  } else if (originUrl.indexOf('https://test-b.rezengroup.cn') >= 0) {
    url = 'https://test2-rezenbusiness.rezengroup.cn';
  }else if (originUrl.indexOf('https://b-sh1.rezenhotels.com') >= 0) {
    url = 'https://rezenbusiness-sh1.rezenhotels.com';
  }
  else {
    url = 'https://rezenbusiness.rezenhotels.com';
  }

  return url;
}


export default {
  handleUrlHost,
  handleUrlHost2,
  handleUrlHostSH,
};
