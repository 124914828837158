<!--
 * @Descripttion: 
 * @version: 
 * @Author: zhaoxiang
 * @Date: 2020-03-11 15:00:56
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-03-11 16:21:21
 -->
<template>
  <div></div>
</template>

<script type="text/ecmascript-6">
import get from 'lodash/get';
import Vue from 'vue';

export default {
  data() {
    return {
    };
  },
  beforeCreate() {
    Object.defineProperty(Vue.prototype, "$toast", {
      value: this
    });
  },
  mounted() {
    window.$eventBus.addEventListener('onMessage', this.handleEvent);
  },
  destroyed() {
    window.$eventBus.removeEventListener('onMessage', this.handleEvent);
  },
  methods: {
    handleEvent(messageEvent) {
      const message = get(messageEvent, ['detail', 'message'], '');
      const type = get(messageEvent, ['detail', 'type'], 'info');
      this.show(type, message);
    },
    show(type, text, during = 5000) {
      const options = {
        type,
        showClose: true,
        message: text,
        duration: during,
        center: false,
      };
      this.$message(options);
    },
  },
};
</script>
