const defaultRouter = {
  path: '/loginPage',
  component: () => import('../views/login/login-page.vue'),
  children: [
    {
      path: '/loginPage',
      name: 'loginForPassWord',
      component: () => import('../views/login/login-for-pass-word.vue'),
    },
    {
      path: '/loginFindBack',
      name: 'loginFindBack',
      component: () => import('../views/login/login-find-back.vue'),
    },
    {
      path: '/setNewPW',
      name: 'setNewPW',
      component: () => import('../views/login/set-new-pw.vue'),
    },
    {
      path: '/setSuccess',
      name: 'setSuccess',
      component: () => import('../views/login/set-success.vue'),
    },
  ],
};
export default defaultRouter;
